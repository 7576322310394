import React, { useState } from 'react';
import { Modal, Input, Table, Checkbox, Button } from 'antd';
import { AreaChartOutlined } from "@ant-design/icons";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { columnsK, columnsO, columnsD, columnsST } from "./VerifyStatsCols"

const { TextArea } = Input;

const CheckboxWithBackground = ({ value, color }) => (
  <div className={color}>
    <Checkbox checked={value} disabled />
  </div>
);

const renderBooleanResult = (value, row, colorClass) => ({
  children: <CheckboxWithBackground value={!!value} color={colorClass} />,
  props: {},
});

const PlayerTable = ({ grades, play }) => {
  const columns = { 'k' : columnsK,  'o' : columnsO, 'd' : columnsD,  'dt' :columnsST }
  return (
    <Table
      columns={columns[play?.odk?.toLowerCase()]}
      dataSource={grades.filter((grade) => grade.first_name)}
      size="middle"
      pagination={false}
      bordered
    />
  );
};

const VerifyStats = ({ play, playInfoColumns, grades }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  return (
    <div className="flex w-[150px]">
            <button  className='btn flex ' onClick={showModal}>
              <span className='btn px-1 py-0  btn-danger  '>
                <AreaChartOutlined  className='relative bottom-[2px]'/> 
              </span>    
              <span className='text-danger font-[600] relative top-[2px] '> Verify Stats</span> 
            </button>      

      <Modal
        title={`Verify Stats`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1100}
      >
        <Table
          pagination={false}
          columns={playInfoColumns}
          dataSource={[play]}
          bordered
          className='mb-3'
        />

<div className="flex justify-end mb-3">
            <div className="flex datas-center font-bold">
                
                <div className="flex flex-col mr-6 text-center">
                    <span className="text-[8px] text-[#526B84]">Execution</span>
                    <span className="text-[18px] text-[#00B21E]">{ (grades.reduce((a,b) =>a+b?.assignment_executed, 0)/ grades.length ) * 100}% <br /></span>
                </div>
                <div className="flex flex-col text-center mr-2">
                    <span className="text-[8px] text-[#526B84]">Effort</span>
                    <span className="text-[18px] text-[#00B21E]">{ (grades.reduce((a,b) =>a+b?.great_effort, 0) / grades.length ) * 100}% <br /></span>
                </div>
                
            </div>
        </div>
        <PlayerTable grades={grades} play={play} />

        <Link className='mt-2' to="/athletes"> Go to depth charts</Link>
      </Modal>


    </div>
  );
};

const mapStateToProps = (state) => ({
  coachProfile: state.app.account.profile.data.details,
});

const mapDispatchToProps = (dispatch) => ({
  addComment: (details) => dispatch(global.redux.action.plays.addComment(details)),
  archiveComment: (details) => dispatch(global.redux.action.plays.archiveComment(details)),
  getCommentsForPlay: (details) => dispatch(global.redux.action.plays.getCommentsForPlay(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyStats);
